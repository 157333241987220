import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// const API_BASE_URL = "http://opus.vayut.com:8000";
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getSiteDetails = createAsyncThunk("get all site", async () => {
  try {
    const testing = await axios.get(`${API_BASE_URL}/sites`);
    return testing.data;
  } catch (error) {
    throw error;
  }});

export const getSiteIdDetails = createAsyncThunk("get based Site id", async (id:string) => {
  try {
    const testing = await axios.get(`${API_BASE_URL}/sites/${id}`);
    return testing.data;
  } catch (error) {
    throw error;
  }
});


export const createSite = createAsyncThunk(
  "create a site",
  async (siteData: any) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/sites/`, siteData);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const deleteSite = createAsyncThunk(
  "delete a site",
  async (siteData: any) => {
    try {
      console.log(siteData);
      const response = await axios.delete(`${API_BASE_URL}/sites/${siteData}`);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

interface InitialState {
  loading: boolean;
  error: boolean;
  siteData: any;
}

const initialState: InitialState = {
  loading: true,
  error: false,
  siteData: [],
};

const siteDetailsSlice = createSlice({
  name: "site",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // builder.addCase(getSiteIdDetails.pending, (state) => {
    // });
    builder.addCase(getSiteDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSiteDetails.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.siteData = payload;
    });
    builder.addCase(getSiteDetails.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default siteDetailsSlice.reducer;
