import { configureStore } from "@reduxjs/toolkit";
import ccSlice from "./ccPersoneData";
import { useDispatch } from "react-redux";
import noticeReducer from "./noticeSlice";
import alertReducer from "./alertSlice";
import projectDetailsReducer from "./projectDetailsSilce";
import newProjectReducer from "./newProjectSlice";
import droneReducer from "./droneSlice";
import droneDetailsReducer from "./droneDetailsSlice";
import jobReducer from "./jobSlice";
import homePageSlice from "./homePageSlice";
import liveJobSlice from "./liveJobSlice";
import droneCreationReducer from "./droneCreationSlice";
import clientDetailsReduer from "./clientSlice";
import siteDetailsReducer from "./siteSlice";
import signupReducer from "./signupSlice";
import mobileReduxPageSlice from "../mobile/mobileLayout/mobileReduxSlice";

const store = configureStore({
  reducer: {
    ccReducer: ccSlice.reducer,
    noticeArr: noticeReducer,
    alertArr: alertReducer,
    projectDetails: projectDetailsReducer,
    newProjectArray: newProjectReducer,
    allDrones: droneReducer,
    droneDetails: droneDetailsReducer,
    jobs: jobReducer,
    homePageSlice: homePageSlice.reducer,
    droneCreationData: droneCreationReducer,
    clientDetails: clientDetailsReduer,
    siteDetails: siteDetailsReducer,
    signup: signupReducer,
    mobileStore:mobileReduxPageSlice.reducer
  },
});
export type IRootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
