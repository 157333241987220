import React, { ReactNode, useState, ErrorInfo } from 'react';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const [state, setState] = useState<ErrorBoundaryState>({ hasError: false });

  const componentDidCatch = (error: Error, errorInfo: ErrorInfo): void => {
    // Log the error to an error reporting service
    console.error(error, errorInfo);
    setState({ hasError: true });
  };

  if (state.hasError) {
    return <p>Something went wrong.</p>;
  }

  return <>{children}</>;
};

export default ErrorBoundary;
