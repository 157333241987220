import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
// const API_BASE_URL = "http://opus.vayut.com:8000";
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getNewProjects = createAsyncThunk(
  "get new projects",
  async (siteId: string) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/projects/?siteId=${siteId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getAllProjects = createAsyncThunk(
  "get new projects",
  async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/projects/`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// export const handleProjectDelete = createAsyncThunk(
//   "delete project wit id",
//   async ({ projectId, siteId }: { projectId: string; siteId: string }) => {
//     try {
//       const response = await axios.delete(
//         `${API_BASE_URL}/projects/${projectId}/`
//       );
//       getNewProjects(siteId);
//       return response;
//     } catch (error) {
//       throw error;
//     }
//   }
// );
export const handleProjectDelete = createAsyncThunk(
  'delete project wit id',
  async ({ projectId, siteId }: { projectId: string; siteId: string }, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/projects/${projectId}/`
      );
      thunkAPI.dispatch(getNewProjects(siteId)); // Dispatch the getNewProjects action
      return response.data; // Return the data instead of the entire response
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  loading: true,
  error: false,
  projectData: [],
};
const newProjectSlice = createSlice({
  name: "newProject",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNewProjects.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(getNewProjects.fulfilled, (state, { payload }) => {
        return { ...state, loading: false, projectData: payload };
      })
      .addCase(getNewProjects.rejected, (state) => {
        return { ...state, loading: false, error: true };
      });
  },
});

export default newProjectSlice.reducer;
