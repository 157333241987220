import { PayloadAction, createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// const API_BASE_URL = "http://opus.vayut.com:8000";
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getChartData = createAsyncThunk(
  "users/fetchById",
  // Declare the type your function argument here:
  async ({ id, date }: { id: any; date: string }) => {
    const response = await axios.get(
      `${API_BASE_URL}/analytics/current_counts/`,
      {
        params: { id, date },
      }
    );

    // Inferred return type: Promise<MyData>
    return response.data;
  }
);

export const getJobs = createAsyncThunk("get job", async (id: any) => {
  try {
    const status = "ACTIVE";
    const response = await axios.get(`${API_BASE_URL}/jobs/?siteId=${id}`, {
      params: { status },
    });
    return response.data;
  } catch {
    return [
      {
        description: "Error Loading Jobs!!!",
        assignedDrones: [{ name: "error" }],
      },
    ];
  }
});

export interface PieChartData {
  title: string;
  value: number;
  total_drones?: number; // Assuming it's optional as it's not present in all pie_chart objects
  healthy_drones?: number; // Optional
  unhealthy_drones?: number; // Optional
  total_pilots?: number; // Optional
  available_pilots?: number; // Optional
  unavailable_pilots?: number; // Optional
  total_jobs?: number; // Optional
  live_jobs?: number; // Optional
  completed_jobs?: number; // Optional
  closed_jobs?: number; // Optional
  pending_jobs?: number; // Optional
  cancelled_jobs?: number; // Optional
}

export interface BarChartData {
  date: string;
  title: string;
  value: number;
  total_jobs: number;
  done_jobs: number;
  pending_jobs: number;
}

export interface JobsWeekly {
  title: string;
  description: string;
  data: BarChartData[];
}
export interface GraphData {
  pie_chart: PieChartData[];
  bar_chart: {
    jobs_weekly: JobsWeekly;
  };
}

interface InitialState {
  jobDetails: any;
  data: GraphData;
  site: any;
}

const initialState: InitialState = {
  site: {},
  jobDetails: [],
  data: {
    pie_chart: [],
    bar_chart: {
      jobs_weekly: {
        title: "",
        description: "",
        data: [],
      },
    },
  },

};

const homePageSlice = createSlice({
  name: "CCPersoneStore",
  initialState,
  reducers: {
    setSiteId(state, { payload }) {
      state.site = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChartData.fulfilled, (state, { payload }) => {
      state.data = payload;
    });
    builder.addCase(getJobs.fulfilled, (state, { payload }) => {
      state.jobDetails = payload;
    });

  },
});

export const { setSiteId } = homePageSlice.actions
export default homePageSlice;
