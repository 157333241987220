import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// const API_BASE_URL = "http://opus.vayut.com:8000";
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getClientDetails = createAsyncThunk(
  "get client details",
  async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/clients`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getPersonnelDetails = createAsyncThunk(
  "get personnel details",
  async () => {
    try {

      const response = await axios.get(`${API_BASE_URL}/personnel`);

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createClient = createAsyncThunk(
  "create client",
  async (payload: any) => {
    const response = await axios.post(
      `${API_BASE_URL}/clients/`,
      payload.formData
    );
    return response.data;
  }
);

export const createPersonnel = createAsyncThunk(
  "create personnel",
  async (payload: any) => {
    const response = await axios.post(
      `${API_BASE_URL}/personnel/`,
      payload.formData
    );
    return response.data;
  }
);

export const deleteClient = createAsyncThunk(
  "delete client",
  async (payload: any) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/clients/${payload.ccId}/`);
      const responseData = { status: response.status };
      return { ccId: payload.ccId, responseData };
    } catch (error) {
      throw error;
    }
  }
);

export const deletePersonnel = createAsyncThunk(
  "delete personnel",
  async (payload: any) => {
    try {const response = await axios.delete(`${API_BASE_URL}/personnel/${payload.ccId}/`);
    const responseData = { status: response.status };
    return { ccId: payload.ccId, responseData };
    } catch (error) {
      throw error;
    }
  }
);

interface InitialState {
  loading: boolean;
  error: boolean;
  clientData: any;
  personnelData: any;
}

const initialState: InitialState = {
  loading: true,
  error: false,
  clientData: [],
  personnelData: [],
};

const clientDetailsSlice = createSlice({
  name: "client",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getClientDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getClientDetails.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.clientData = payload;
    });
    builder.addCase(getClientDetails.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(getPersonnelDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPersonnelDetails.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.personnelData = payload;
    });
    builder.addCase(getPersonnelDetails.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default clientDetailsSlice.reducer;
