import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// const API_BASE_URL = 'http://opus.vayut.com:8000';
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getAlerts = createAsyncThunk("get alert",async(org:string)=> {
    try {
        const response = await axios.get(`${API_BASE_URL}/alert/?orgId=${org}`)
        return response.data;
    }
    catch {
        return [{ user: 'SYS', description: 'Error Loading alert!!!' }]
    }
})
export const patchAlert = createAsyncThunk('patch alert', async(data:any)=> {
  try {
    const response = await axios.patch(`${API_BASE_URL}/alert/${data.id}/`,data)
  return response.data
  }
  catch {
    return
  }
})

interface InitialState {
loading: boolean,
error: boolean,
alertData: any
}

const initialState: InitialState = {
  loading: true,
  error: false,
  alertData: []
}

const alertSlice = createSlice({
    name: "projectalert",
    initialState,
    reducers: {},
    extraReducers: (builder)=> {
      builder.addCase(getAlerts.pending,(state)=> {
        state.loading = true
      });
      builder.addCase(getAlerts.fulfilled,(state,{payload})=> {
        state.loading = false
        state.alertData = payload
      })
      builder.addCase(getAlerts.rejected,(state)=> {
        state.loading = false
        state.error = true
        state.alertData = [{ user: 'SYS', description: 'Error Loading alert!!!' }]
      })
    }
})

export default alertSlice.reducer
