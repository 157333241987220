import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// const API_BASE_URL = "http://opus.vayut.com:8000";
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getJobs = createAsyncThunk("get job", async (id: any) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/jobs/?projectId=${id}`);
    return response.data;
  } catch(error) {
     throw error
  }
});

export const deleteJob = createAsyncThunk(
  "delete job",
  async (payload: any) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/jobs/${payload.ccId}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

interface InitialState {
  loading: boolean;
  error: boolean;
  jobData: any;
}

const initialState: InitialState = {
  loading: true,
  error: false,
  jobData: [],
};

const jobSlice = createSlice({
  name: "projectjob",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getJobs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getJobs.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.jobData = payload;
    });
    builder.addCase(getJobs.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default jobSlice.reducer;
