import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_URL;


export const createUser = createAsyncThunk(
  "signup user",
  async (payload: any) => {
    const url = `${API_BASE_URL}`;
    try {
      delete payload.type;
      // console.log(payload)
      const response = await axios.post(`${url}/personnel/register/`, payload);
      return response.data;
    } catch (error: any) {
      error.message = error?.response?.data;
      throw error;
    }
  }
);

export const loginUser = createAsyncThunk(
  "signup user",
  async (payload: any) => {
    const url = `${API_BASE_URL}`;
    try {
      const response = await axios.post(`${url}/auth/login/`, payload);
      // console.log("payload", payload);
      // console.log("auth Data", response.data);
      localStorage.setItem("AuthTokens", response.data[0]);
      return response.data;
    } catch (error: any) {
      error.message = error?.response?.data;
      throw error;
    }
  }
);

export const dispatchOtp = createAsyncThunk(
  "confirm user",
  async (payload: any) => {
    const url = `${API_BASE_URL}`;
    try {
      const response = await axios.post(`${url}/auth/confirm_signup/`, payload);
      return response.data;
    } catch (error: any) {
      // console.log(error)
      error.message = "OTP code invalid or expired";
      throw error;
    }
  }
);

export const validateUser = createAsyncThunk('validate user', async () => {
    const url = `${API_BASE_URL}`
    try {


        const response = await axios.get(
            `${url}/personnel/profile/`,
        );
        // console.log(response.data)
        return response.data;
    }
    catch (error:any) {
        error.message = error?.response?.data
        throw error
    }
})

const initialState = {
  data: {
    email: "",
    name: "",
    phone: "",
    address: "",
  },
  error: "",
  signed_in: false,
};

const signupSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    updateSignedInStatus: (state, { payload }) => {
      return { ...state, signed_in: payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createUser.pending, () => {});

    builder.addCase(createUser.fulfilled, (state, { payload }) => {
      // console.log(payload)
      return {
        ...state,
        data: { ...state.data, email: payload[1].email },
        error: "",
      };
    });

    builder.addCase(createUser.rejected, (state, { error} :any) => {
        console.log(error)
        return { ...state, data: { ...state.data }, error: error.message || "An error occured" }
    });

    builder.addCase(validateUser.fulfilled, (state, { payload }) => {
        return { ...state, data: { ...state.data, email: payload.email }, error: "" }
    });

    builder.addCase(dispatchOtp.fulfilled, (state, { error} :any) => {
        console.log(error)
        return { ...state, error: "" }
    });

    builder.addCase(dispatchOtp.rejected, (state, { error} :any) => {
        console.log(error)
        return { ...state, error: error.message }
    });
    }
})

export const { updateSignedInStatus } = signupSlice.actions;


export default signupSlice.reducer;
