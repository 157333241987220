import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// const API_BASE_URL = "http://opus.vayut.com:8000";
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getProjectDetails = createAsyncThunk(
  "get project details",
  async (id: string) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/projects/${id}`);
      const jobs = await axios.get(`${API_BASE_URL}/jobs/?projectId=${id}`)
      const alerts = await axios.get(`${API_BASE_URL}/alert/?orgId=${response.data.org.id}`)
      // console.log(response)
      let completedJobsCount = 0
      jobs.data.map((job: any) => {
        if (job.status === "completed") {
          completedJobsCount += 1
        }
      })
      response.data.jobCount = jobs.data.length
      response.data.alertCount = alerts.data.length
      response.data.completedJobsCount = completedJobsCount
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createCCperson = createAsyncThunk(
  "create cc",
  async (payload: any) => {
    const response = await axios.post(
      `${API_BASE_URL}/projects/CC/?projectid=${payload.id}`,
      payload.formData
    );
    return response.data;
  }
);

export const deleteCCPerson = createAsyncThunk(
  "delete cc",
  async (payload: any) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/projects/personal/?projectId=${payload.id}&personId=${payload.ccId}`, {
        transformResponse: [(data) => JSON.parse(data)],
      }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

interface InitialState {
  loading: boolean;
  error: boolean;
  projectData: any;
}

const initialState: InitialState = {
  loading: true,
  error: false,
  projectData: { ccPersonnel: [], assignedPersonnel: [], assignedDrones: [] },
};

const projectDetailsSlice = createSlice({
  name: "projectalert",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProjectDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProjectDetails.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.projectData = payload;
    });
    builder.addCase(getProjectDetails.rejected, (state) => {
      state.loading = false;
      state.error = true;
      // state.projectData = [{ user: 'SYS', description: 'Error Loading alert!!!' }]
    });
  },
});

export default projectDetailsSlice.reducer;
