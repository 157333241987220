import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// const API_BASE_URL = "http://opus.vayut.com:8000";
const API_BASE_URL = process.env.REACT_APP_API_URL;
export const getDronesDetails = createAsyncThunk(
  "get drone details",
  async (id: string) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/drone/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getJobs = createAsyncThunk("get job", async ({ id }: { id: string }) => {
  // console.log(id,status)
  try {
    const response = await axios.get(`${API_BASE_URL}/jobs/?droneId=${id}`, {
      // params: { status },
    });
    return response.data;
  } catch {
    return [
      {
        description: "Error Loading Jobs!!!",
        assignedDrones: [{ name: "error" }],
      },
    ];
  }
});

interface InitialState {
  loading: boolean;
  error: boolean;
  detailsData: any;
  reservedDrones: any;
  liveLocation : any;
  droneStatus : any;
  api_key : any;
  header_config:any;
  droneDetails :any;
  jobDetails: any;

}

const initialState: InitialState = {
  loading: true,
  error: false,
  detailsData: { id: "" },
  reservedDrones: [],
  liveLocation : {},
  droneStatus : {},
  api_key :  "",
  header_config : {},
  droneDetails : {},
  jobDetails: [],

};

const droneDetailsSlice = createSlice({
  name: "projectNotice",
  initialState,
  reducers: {
    updateHeaderConfig : (state,{payload})=>{
      return {...state,header_config : payload}
    },
    updateDroneDetails :(state,{payload})=>{
      return {...state,droneDetails : payload}
    },
    updateApiKey : (state, { payload }) => {
      state.api_key = payload;
    },
    updateDetailsData: (state, { payload }) => {
      state.detailsData = payload;
    },
    updateReservedDrones: (state, { payload }) => {

      const itemExists = state.reservedDrones.some(
        (item: any) => item.id === payload.id
      );
      if (itemExists) {
        return {
          ...state,
          reservedDrones: state.reservedDrones.filter(
            (item: any) => item.id !== payload.id
          ),
        };
      } else {
        return {
          ...state,
          reservedDrones: [...state.reservedDrones, payload],
        };
      }
    },
    updateLiveLocation : (state,{payload})=>{
      return {...state,liveLocation : payload}
    },
    updateLiveStatus : (state,{payload})=>{
      return {...state,droneStatus : payload}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDronesDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDronesDetails.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.detailsData = payload;
    });
    builder.addCase(getDronesDetails.rejected, (state) => {
      state.loading = false;
      state.error = true;
      // state.detailsData = [{ user: 'SYS', description: 'Error Loading Notice!!!' }]
    });
    builder.addCase(getJobs.fulfilled, (state, { payload }) => {
      state.jobDetails = payload;
    });
  },
});

export default droneDetailsSlice.reducer;

export const { updateDetailsData, updateReservedDrones,updateLiveLocation,updateLiveStatus,updateApiKey,updateHeaderConfig,updateDroneDetails } =

  droneDetailsSlice.actions;
