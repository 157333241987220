import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// const API_BASE_URL = 'http://opus.vayut.com:8000';
const second_api = "http://opus.vayut.com:8000"
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getDrones = createAsyncThunk("get all drones", async () => {
  try {
    const testing = await axios.get(`${API_BASE_URL}/drone`)
    return testing.data;
  } catch (error) {
    throw error;
  }
});

export const getDroneTickets = createAsyncThunk('get drone tickets', async (id: string) => {
  try {
    const response = await axios.get(`${second_api}/tickets/?type=drone`)
    return response.data;
  }
  catch (error) {
    throw error
  }
})

export const getJobTickets = createAsyncThunk('get job tickets', async (id: string) => {
  try {
    const response = await axios.get(`${second_api}/tickets/?type=job`)
    return response.data
  }
  catch (error) {
    throw error
  }
})

export const createDrone = createAsyncThunk('create a drone', async (droneData: any) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/drone/`, droneData);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
});

export const deleteDroneFromApi = createAsyncThunk('delete a drone', async (droneData: any) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/drone/${droneData}`);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
});

export const addDrone = createAsyncThunk("add drone", async (payload: any) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/projects/drones/?projectId=${payload.id}&droneId=${payload.confirmData}`
    );
    return response.data
  } catch (error) {
    // Handle error
    console.error("Error creating project:", error);
    throw error;
  }
});

export const deleteDrone = createAsyncThunk("delete drone", async (payload: any) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/projects/drones/?projectId=${payload.id}&droneId=${payload.confirmData}`
    );
    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error creating project:", error);
    throw error;
  }
});


interface InitialState {
  loading: boolean,
  error: boolean,
  droneData: any
}

const initialState: InitialState = {
  loading: true,
  error: false,
  droneData: []
}

const droneSlice = createSlice({
  name: "projectNotice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDrones.pending, (state) => {
      state.loading = true
    });
    builder.addCase(getDrones.fulfilled, (state, { payload }) => {
      state.loading = false
      state.droneData = payload
    })
    builder.addCase(getDrones.rejected, (state) => {
      state.loading = false
      state.error = true
    })
  }
})

export default droneSlice.reducer
