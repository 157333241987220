import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// const API_BASE_URL = 'http://opus.vayut.com:8000';
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getNotice = createAsyncThunk("get notice",async(id:string)=> {
  console.log(id)
    try {
        const response = await axios.get(`${API_BASE_URL}/notice/?projectId=${id}`)
        return response.data;
    }
    catch {
        return [{ user: 'SYS', description: 'Error Loading Notice!!!' }]
    }
})

export const postNotice = createAsyncThunk("post notice",async (data: any) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/notice/`, data)
        return response.data;
    }
    catch {
        return [{ user: 'SYS', description: 'Error Loading Notice!!!' }]
    }
}
)

interface InitialState {
loading: boolean,
error: boolean,
noticeData: any
}

const initialState: InitialState = {
  loading: true,
  error: false,
  noticeData: []
}

const noticeSlice = createSlice({
    name: "projectNotice",
    initialState,
    reducers: {},
    extraReducers: (builder)=> {
      builder.addCase(getNotice.pending,(state)=> {
        state.loading = true
      });
      builder.addCase(getNotice.fulfilled,(state,{payload})=> {
        state.loading = false
        state.noticeData = payload
      })
      builder.addCase(getNotice.rejected,(state)=> {
        state.loading = false
        state.error = true
        state.noticeData = [{ user: 'SYS', description: 'Error Loading Notice!!!' }]
      })
    }
})

export default noticeSlice.reducer
