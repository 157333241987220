import {PayloadAction, createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

// const API_BASE_URL = "http://opus.vayut.com:8000";
const API_BASE_URL = process.env.REACT_APP_API_URL;

interface InitialState {
  jobDetailsData: any;
  droneDetailsData: any;
}

const initialState: InitialState = {
  jobDetailsData: null,
  droneDetailsData: null,
};

const mobileReduxPageSlice = createSlice({
  name: "MobileStore",
  initialState,
  reducers: {
    setJobDetailsData(state, action) {
      state.jobDetailsData = action.payload;
    },
    setDroneDetailsData(state, action) {
      state.droneDetailsData = action.payload;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(someAsyncAction.fulfilled, (state, action) => {})
  },
});
export const {actions: mobileReduxActions, reducer: mobileReduxReducer} = mobileReduxPageSlice;
export const {setJobDetailsData, setDroneDetailsData} = mobileReduxActions;

export default mobileReduxPageSlice;
