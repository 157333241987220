import React, { useState, useEffect,lazy, Suspense  } from "react";
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import RedirectToLogin from "./pages/page not found/RedirectToLogin";
import { updateSignedInStatus } from "./store/signupSlice";
import { MapComp } from "./components/generalMapComp/generalMapComp";
import { updateUnavailableEntities } from "./store/droneCreationSlice";

import wifi from "./images/free-wifi-slash-3605401-3005481.png";
import axios from "axios";
import "./App.css";
const ProjectList = lazy(() => import("./pages/project-list"));
const Layout = lazy(() => import("./pages/Layout"));
const Inventory = lazy(() => import("./components/inventory"));
const JobCreation = lazy(() => import("./components/job-creation"));
const Map = lazy(() => import("./components/mission-map/missionMap"));
const HomePage = lazy(() => import("./pages/home-page/homePage"));
const LiveJob = lazy(() => import("./pages/live-job"));

const LoginPage = lazy(() => import("./login/login"));
const SignUpPage = lazy(() => import("./sign up/signUp"));
const SignUpWithPage = lazy(() => import("./sign up/signUpWith"));
const PageNotFound = lazy(() => import("./pages/page not found/pageNotFound"));

const LivePositions = lazy(() => import("./pages/live-position/livePositions"));
const DroneInfo = lazy(() => import("./pages/droneInfo"));
const PrivateRoute = lazy(() => import("./privateRoute/privateRoute"));
const UseOpusFor = lazy(() => import("./sign up/signup and company creation page/useOpusfor/useOpusfor"));

const CompanySizePage = lazy(() => import("./sign up/signup and company creation page/companySize/companySize"));
const TeamSizePage = lazy(() => import("./sign up/signup and company creation page/teamSize/teamSize"));
const StartWithPage = lazy(() => import("./sign up/signup and company creation page/startWith/startWith"));
const PhoneNumberPage = lazy(() => import("./sign up/signup and company creation page/phoneSetup/phoneNumber"));

const InvitePeoplePage = lazy(() => import("./sign up/signup and company creation page/invitePeople/invitePeople"));
const Tools = lazy(() => import("./sign up/signup and company creation page/tools/tools"));
const CompanyNamePage = lazy(() => import("./sign up/signup and company creation page/companyName/companyName"));
const InventoryManagerPage = lazy(() => import("./pages/inventory-Manager/inventoryManager"));

const LiveDronesPage = lazy(() => import("./pages/liveDrones/liveDrones"));
const DashboardPage = lazy(() => import("./pages/dashboard/dashboard"));
const CreateChartsPage = lazy(() => import("./components/dashboardPages/createCharts"));
const SiteManagerPage = lazy(() => import("./pages/siteManager/siteManager"));

const ClientPage = lazy(() => import("./pages/clients/client"));
const PersonnelPage = lazy(() => import("./pages/personnels/personnel"));
const OtpPage = lazy(() => import("./sign up/signup and company creation page/otp/Otp"));
const Lobby = lazy(() => import("./pages/lobby/lobby"));


const Spinner = lazy(() => import("./shared/spinner/spinner"));
const MapExampleUsage = lazy(() => import("./components/MapComponent/exampleUsage"));
const FileDraggable = lazy(() => import("./pages/fileDraggable/fileDraggable"));
const MobileLayout = lazy(() => import("./mobile/mobileLayout/mobileLayout"));

const JobsPage = lazy(() => import("./mobile/jobFolder/jobs"));
const TicketsPage = lazy(() => import("./mobile/ticketFolder/tickets"));
const DronesMobilePage = lazy(() => import("./mobile/droneFolder/mobileDrones"));
const DroneDetailsMobilePage = lazy(() => import("./mobile/droneFolder/mobileDronePage"));


const JobDetailsPage = lazy(() => import("./mobile/jobFolder/jobDetails"));
const TicketDetailsPage = lazy(() => import("./mobile/ticketFolder/ticketDetails"));
const JobsListuniversalPage = lazy(() => import("./pages/jobsListUniversal/JobsListuniversalPage"));
const OpenTicketsPage = lazy(() => import("./mobile/mobileLayout/openTickets"));
const WebTicketDetails = lazy(()=> import("./pages/webTickets/WebTicketDetails"));

const UniversalTicketsPage = lazy(() => import("./mobile/ticketFolder/universalTicket"));
const WebTicketsPage = lazy(() => import("./pages/webTickets/webTicketsPage"));
const MobileLoginPage = lazy(() => import("./mobile/mobileLogin/mobileLogin"));
const AnalyticsPage = lazy(() => import("./pages/geolocation/useGeoLocation"));
const UseGeoLocation = lazy(() => import("./pages/geolocation/useGeoLocation"));


const ProjectLandingPage = lazy(() => import("./pages/ProjectLandingPage"));
const MapGUI = lazy(() => import("./pages/mapGUI/mapGUIPage"));
const DroneTicketsPage = lazy(() => import("./mobile/mobileLayout/droneTicket"));



function App() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOnline, setIsOnline] = useState(true);
  const dispatch = useDispatch();

  // const getAuthToken = () => {
  //   return localStorage.getItem("AuthTokens");
  // };

  // const requestInterceptor = axios.interceptors.request.use((config) => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false)
  //   }, 2000);
  //   const authToken = getAuthToken();

  //   if (authToken) {
  //     config.headers.Authorization = `Bearer ${authToken}`;
  //   }
  //   return config;
  // });

  // const responseInterceptor = axios.interceptors.response.use(
  //   (response:any) => {
  //     try {
  //       setLoading(false);
  //       setError(false);
  //       return response;
  //     }
  //     catch (error){
  //       setLoading(false);
  //     }
  //   }

  // );
  // useEffect(() => {
  //   return () => {
  //     if (performance.navigation.type === 1) {
  //       axios.interceptors.request.eject(requestInterceptor);
  //       axios.interceptors.response.eject(responseInterceptor);
  //     } else {
  //       axios.interceptors.request.eject(requestInterceptor);
  //       axios.interceptors.response.eject(responseInterceptor);
  //     }
  //   };
  // }, [requestInterceptor, responseInterceptor]);

  useEffect(() => {
    let requestInterceptor: number;
    let responseInterceptor: number;
  
    const getAuthToken = () => {
      return localStorage.getItem("AuthTokens");
    };
  
    const setupInterceptors = () => {
      // Request interceptor
      requestInterceptor = axios.interceptors.request.use((config) => {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
  
        const authToken = getAuthToken();
  
        if (authToken) {
          config.headers.Authorization = `Bearer ${authToken}`;
        }
        return config;
      });
  
      // Response interceptor
      responseInterceptor = axios.interceptors.response.use(
        (response: any) => {
          setLoading(false);
          setError(false);
          return response;
        },
        (error) => {
          setLoading(false);
          if(error.message === 'Request failed with status code 401'){
            localStorage.removeItem("AuthTokens");
          }
          return Promise.reject(error);
        }
      );
    };
  
    setupInterceptors();
  
    return () => {
      // Eject interceptors on component unmount
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []); // Empty dependency array ensures that this effect runs only once, similar to componentDidMount
  
  const signedin: any = useSelector((store: any) => store.signup.signed_in);

  return (
    <>
      {!isOnline && (
        <div className="network-status-message">
          <div className="fixed z-[9999] top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-[#F0F1F3]  backdrop-filter backdrop-blur-sm overflow-auto">
            <div className="">
              <div className="bg-transparent rounded-lg" style={{ width: "100%" }}>
                <img src={`${wifi}`} className="mx-auto w-24 h-24 " />

                <p className="text-center mt-2 font-Garamond text-2xl font-medium">Network is not working. Please check your internet connection ....</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <Spinner loading={loading} />
      <BrowserRouter>
      <Suspense fallback={<div>loading...</div>}>

        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index path="/" element={<Navigate to="login" replace />} /> {/* <Route path="contact" element={<Contact />} /> */}
            <Route path="live-positions" element={<LivePositions />} />
            <Route element={<PrivateRoute error={error} />}>
              <Route path="home-page/*" element={<HomePage />} />
              <Route path="/project-list/:id" element={<ProjectList />} />
              <Route path="map" element={<MapExampleUsage />} />
              <Route path="/landing-page/:id" element={<ProjectLandingPage />} />
              <Route path="/livejob/:id" element={<LiveJob />} />
              <Route path="/droneInfo/:id" element={<DroneInfo />} />
              <Route path="job-creation/:id" element={<JobCreation />} />
              <Route path="/site-manager" element={<SiteManagerPage />} />
              <Route path="/tickets" element={<WebTicketsPage />} />
              <Route path="/ticket/:id" element={<WebTicketDetails />} />
              <Route path="/live-drones" element={<LiveDronesPage />} />
              <Route path="/clients" element={<ClientPage />} />
              <Route path="/personnels" element={<PersonnelPage />} />
              <Route path="files" element={<FileDraggable />} />
              <Route path="/inventory-manager" element={<InventoryManagerPage />} />
              <Route path="/lobby" element={<Lobby />} />
              <Route path="/allJobs" element={<JobsListuniversalPage />} />
              <Route path="/analytics" element={<AnalyticsPage />} />
              <Route path="/useGeolocation" element={<UseGeoLocation />} />
              <Route path="/mapGUI" element={<MapGUI />}/>

            </Route>
          </Route>

          <>
            <Route path="/sign-up" element={<SignUpPage />} />
            {/* <Route path="/sign-up" element={<SignUpPage />} /> */}
            <Route path="/login" element={<LoginPage error={error} />} />
            <Route path="/mobileLogin" element={<MobileLoginPage error={error} />} />
            <Route path="/sign-up-with" element={<SignUpWithPage />} />
            <Route path="/use-opus-for" element={<UseOpusFor />} />
            <Route path="/company-size" element={<CompanySizePage />} />
            <Route path="/team-size" element={<TeamSizePage />} />
            <Route path="/start-with" element={<StartWithPage />} />
            <Route path="/phoneNumber" element={<PhoneNumberPage />} />
            <Route path="/confirm-user" element={<OtpPage />} />
            <Route path="/invite-people" element={<InvitePeoplePage />} />
            <Route path="/company-name" element={<CompanyNamePage />} />
            <Route path="/live-drones" element={<LiveDronesPage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/charts" element={<CreateChartsPage />} />
            <Route path="/tools" element={<Tools />} />

            <Route path="*" element={<PageNotFound />} />
          </>
          <Route path="/mobile" element={<MobileLayout />}>
            <Route index path="/mobileLogin" element={<Navigate to="mobileLogin" replace />} />
            <Route element={<PrivateRoute error={error} />}>
              <Route path="jobs/:id" element={<JobsPage />} />
              <Route path="tickets" element={<TicketsPage />} />
              <Route path="drones" element={<DronesMobilePage />} />
              <Route path="drones-details/:id" element={<DroneDetailsMobilePage />} />
              <Route path="job-details/:id" element={<JobDetailsPage />} />
              <Route path="ticket-details/:id" element={<TicketDetailsPage />} />
              <Route path="open-tickets" element={<OpenTicketsPage />} />
              <Route path="universal-tickets" element={<UniversalTicketsPage />} />
              <Route path="drone-tickets" element={<DroneTicketsPage />} />
            </Route>
          </Route>
        </Routes>
        </Suspense>

      </BrowserRouter>


    </>
  );
}

export default App;
