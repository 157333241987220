import { PayloadAction, createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ProjectData } from "../pages/project-list";

// const API_BASE_URL = "http://opus.vayut.com:8000";
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getCCProjectDetails = createAsyncThunk<ProjectData, string>(
  "users/fetchById",
  // Declare the type your function argument here:
  async (id: string) => {
    const response = await axios.get(`${API_BASE_URL}/projects/${id}`);
    // Inferred return type: Promise<MyData>
    return response.data;
  }
);

export const getCCProject = createAsyncThunk<Array<string>, string>(
  "users/fetchById2",
  // Declare the type your function argument here:
  async (id: string) => {
    const response = await axios.get(`${API_BASE_URL}/projects/${id}`);
    // Inferred return type: Promise<MyData>
    return response.data;
  }
);

interface IInitialState {
  projectData: ProjectData | Record<string, never>;
  ccDetails: Array<string>;
}

const initialState: IInitialState = {
  projectData: {},
  ccDetails: [],
};

const ccSlice = createSlice({
  name: "CCPersoneStore",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCCProjectDetails.fulfilled, (state, { payload }) => {
      state.projectData = payload;
    });
    builder.addCase(getCCProject.fulfilled, (state, { payload }) => {
      state.ccDetails = payload;
    });
  },
});

export default ccSlice;
