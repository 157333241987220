import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getSingleProject = createAsyncThunk("get single project", async (id: string) => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await axios.get(`${API_BASE_URL}/projects/${id}`);
    const jobTypes = await axios.get(`${API_BASE_URL}/jobs/types/`);
    const missions = await axios.get(`${API_BASE_URL}/missions/`);
    const users = await axios.get(`${API_BASE_URL}/personnel/`);
    const clients = await axios.get(`${API_BASE_URL}/clients/`)
    response.data.jobTypes = jobTypes.data
    response.data.missions = missions.data
    response.data.users = users.data
    response.data.clients = clients.data
    return response.data;
  } catch (error) {
    throw error;
  }
})

export const getInvoice = createAsyncThunk('get invoice', async (payload: string) => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  try {
    const response = await axios.get(`${API_BASE_URL}/invoice/${payload}`)
    return response.data
  }
  catch (error) {
    throw error
  }
})

export const postNewJob = createAsyncThunk('post new job', async (payload: any) => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  try {
    const response = await axios.post(`${API_BASE_URL}/jobs/`, payload)
    const feedback = { ...response?.data }
    feedback.apiStatus = response?.status
    return feedback
  }
  catch (error) {
    throw error
  }
})

export const postAssignedJobUnits = createAsyncThunk('post assigned drones', async (payload: any) => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await axios.post(`${API_BASE_URL}/jobs/flightunits/?jobId=${payload.jobId}&flightUnitId=${payload.flightUnitId}`)
    return response.data
  }
  catch (error) {
    throw error
  }
})

export const postService = createAsyncThunk('post service', async (payload: any) => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  try {
    const response = await axios.post(`${API_BASE_URL}/invoice/item/`, payload)
    return response.data
  }
  catch (error) {
    throw error
  }
})

export const patchService = createAsyncThunk('post service', async (payload: any) => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  try {
    const response = await axios.patch(`${API_BASE_URL}/invoice/item/${payload.id}/`, payload.data)
    return response.data
  }
  catch (error) {
    throw error
  }
})

export const createInvoice = createAsyncThunk('create invoice', async (payload: any) => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  try {
    const response = await axios.post(`${API_BASE_URL}/invoice/`, payload)
    return response.data
  }
  catch (error) {
    throw error
  }
})

export const patchInvoice = createAsyncThunk('update invoice', async (payload: any) => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  try {
    const response = await axios.patch(`${API_BASE_URL}/invoice/${payload.id}/`, payload.data)
    return response.data
  }
  catch (error) {
    throw error
  }
})

export const deleteInvoice = createAsyncThunk('delete invoice', async (payload: any) => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  try {
    await axios.delete(`${API_BASE_URL}/invoice/item/${payload}/`)
  }
  catch (error) {
    throw error
  }
})


export const patchJob = createAsyncThunk('patch job', async (payload: any) => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  try {
    const response = await axios.patch(`${API_BASE_URL}/jobs/${payload.id}/`, payload.data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    console.log(response)
    const feedback = { ...response?.data }
    feedback.apiStatus = response?.status
    return feedback
  }
  catch (error:any) {
    const feedback = {...error}
    return feedback?.response?.data?.unavailable
  }
})

export const postFlightUnit = createAsyncThunk('create flight unit', async (payload: any) => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  try {
    const response = await axios.post(`${API_BASE_URL}/flightunit/`, payload)
    return response.data
  }
  catch (error) {
    throw error
  }
})

export const patchFlightUnit = createAsyncThunk('create flight unit', async (payload: any) => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  try {
    const response = await axios.patch(`${API_BASE_URL}/flightunit/${payload.id}/`, payload.data)
    return response.data
  }
  catch (error) {
    throw error
  }
})

export const deleteFlightUnit = createAsyncThunk('delete flight unit', async (payload: any) => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  try {
    await axios.delete(`${API_BASE_URL}/flightunit/${payload}/`)
  }
  catch (error) {
    throw error
  }
})

export const postFiles = createAsyncThunk('post files', async (payload: any) => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  try {
    console.log(payload.data)
    const response = await axios.post(
      `${API_BASE_URL}/jobs/files/?jobId=${payload.jobId}`,
      payload.data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data; // Return the response data
  } catch (error) {
    console.error('Error posting files:', error);
    throw error; // Re-throw the error so that it can be caught in the component
  }
});

export const deleteFile = createAsyncThunk('delete file', async (payload: any) => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  try {
    await axios.delete(`${API_BASE_URL}/files/${payload}/`)
  }
  catch (error) {
    throw error
  }
})


interface MissionData {
  type: string;
  time: string;
  waypoints: any;
  totalDistance: string;
  totalPols: string;
  details: string;
  mapLayers: any;
  liveLocations: any;
  assignedDrone: any;
  droneJob: any;
}

interface DroneState {
  missionData: MissionData;
  droneKpiData: {
    type: string;
    time: string;
    waypoints: any;
    totalDistance: string;
    totalPols: string;
    mapLayers: any;
    liveLocations: any;
    assignedDrone: any;
    files: any;
    services: any;

  };
  newJob: any
}

const initialState: DroneState = {
  missionData: {
    type: "",
    time: "",
    waypoints: [],
    totalDistance: "",
    totalPols: "",
    details: "",
    mapLayers: [],
    liveLocations: [],
    assignedDrone: {
      info: [],
      info2: [],
      lifeSpan: "",
    },
    droneJob: {}
  },
  newJob: {
    name: "",
    total: 0,
    otherBills: "",
    project: "",
    projectName: "",
    orgId: "",
    orgName: "",
    orgDescription: "",
    orgManager: "",
    managerEmail: "",
    description: "",
    type: {},
    site: {},
    manager: { name: "", address: "", phone: "" },
    client: { name: "", address: "", phone: "" },
    startDate: "",
    endDate: "",
    createdOn: "",
    billingAddressName: "",
    billingAddressLocation: "",
    billingAddressPhone: "",
    shippingAddressName: "",
    shippingAddressLocation: "",
    shippingAddressPhone: "",
    confirmedPilot: { name: "", type: "", id: "" },
    apiType: "post",
    pilot: { id: "" },
    org: { name: "" },
    assignedMission: { id: "" },
    assignedPersonnel: [],
    jobTypes: [],
    missions: [],
    files: [],
    services: [],
    flightunits: [],
    users: [],
    clients: [],
    invoice: [],
    unavailable: { pilots: [], drones: [] },
    filledServices: true
  },
  droneKpiData: {
    type: "",
    time: "",
    waypoints: [],
    totalDistance: "",
    totalPols: "",
    mapLayers: [],
    liveLocations: [],
    assignedDrone: {
      info: [],
      info2: [],
      lifeSpan: "",
    },
    files: [],
    services: [],
  },
};

const droneCreation = createSlice({
  name: "drone creation",
  initialState,
  reducers: {
    updateAllData: (state, { payload }) => {
      return {
        ...state,
        missionData: {
          ...state.missionData,
          type: payload.missionData.type,
          time: payload.missionData.time,
          waypoints: payload.missionData.waypoints,
          totalDistance: payload.missionData.totalDistance,
          totalPols: payload.missionData.totalPols,
          mapLayers: payload.missionData.mapLayers,
        },
        droneKpiData: {
          ...state.droneKpiData,
          type: payload.droneKpiData.type,
          time: payload.droneKpiData.time,
          waypoints: payload.droneKpiData.waypoints,
          totalDistance: payload.droneKpiData.totalDistance,
          totalPols: payload.droneKpiData.totalPols,
          mapLayers: payload.droneKpiData.mapLayers,
        },
      };
    },
    updateDroneJob: (state, { payload }) => {
      state.missionData.droneJob = payload;
    },
    updateMapLayers: (state, { payload }) => {
      state.missionData.mapLayers = payload;
      state.missionData.waypoints = payload[0].latlngs;
    },
    updateMapLayersWithIndex: (state, { payload }) => {

      state.missionData.mapLayers[payload.index].latlngs = payload.waypoints;
    },
    updateMissionDetails: (state, { payload }) => {
      return {
        ...state,
        missionData: { ...state.missionData, details: payload.details },
      };
    },
    updateCurrentPosition: (state, { payload }) => {
      state.missionData.liveLocations = payload;
    },
    updateAssignedDrone: (state, { payload }) => {
      state.missionData.assignedDrone = payload;
    },
    updateFiles: (state, { payload }) => {
      return {
        ...state,
        newJob: {
          ...state.newJob,
          files: [...state.newJob.files, payload],
        },
      };
    },
    addService: (state, { payload }) => {
      payload.id = Math.random()
      if (state.newJob.services.length > 0) {
        const lastJob = state.newJob.services[state.newJob.services.length - 1]
        if (lastJob.quantity && lastJob.description && lastJob.price) {
          return {
            ...state,
            newJob: {
              ...state.newJob,
              services: [...state.newJob.services, payload], filledServices: false
            },
          }
        }
        else {
          state.newJob.filledServices = false
        }
      }
      else {
        return {
          ...state,
          newJob: {
            ...state.newJob,
            services: [...state.newJob.services, payload], filledServices: false
          },
        }
      }
    },
    addUser: (state, { payload }) => {
      return {
        ...state,
        newJob: { ...state.newJob, assignedPersonnel: [...payload] },
      };
    },
    updateflightunits: (state, { payload }) => {
      const itemExists = state.newJob.flightunits.some(
        (item: any) => item.drone.id === payload.id
      );

      if (itemExists) {
        return {
          ...state,
          flightunits: state.newJob.flightunits.filter(
            (item: any) => item.drone.id !== payload.id
          ),
        };
      } else {
        return {
          ...state, newJob: { ...state.newJob, flightunits: [...state.newJob.flightunits, { drone: payload, missions: [], pilot: { id: "" }, gottenFromApi: false, id: Math.random() }] }
        };
      }
    },

    removeFlightUnit: (state, { payload }) => {
      const newUnits = state.newJob.flightunits.filter((unit: any) => unit.id !== payload.id)
      state.newJob.flightunits = newUnits
    },
    removeLocalInvoice: (state, { payload }) => {
      console.log(payload)
      const newInvoice = state.newJob.services.filter((invoice: any) => invoice.id !== payload)
      state.newJob.services = newInvoice
    },

    updateJobCreationStringData: (state, { payload }) => {

      return { ...state, newJob: { ...state.newJob, [payload.type]: payload.data } }
    },
    updateCreationDate: (state, { payload }) => {
      return { ...state, newJob: { ...state.newJob, creationDate: payload } }
    },
    updateServiceData: (state, { payload }) => {
      const scanServices = state.newJob.services.map((element: any) => {
        if (element.id == payload.id) {
          element[payload.type] = payload.value
        }
      })
    },
    updateStartAndEndDates: (state, { payload }) => {
      return { ...state, newJob: { ...state.newJob, [payload.type]: payload.value } }
    },
    sendDataToApi: (state) => {
      const personnelArr: any = []
      state.newJob.assignedPersonnel.map((personnel: any) => {
        personnelArr.push(personnel.id)
      })
      return { ...state, newJob: { ...state.newJob, assignedPersonnel: personnelArr } }
    },
    arrangeDataForEdit: (state, { payload }) => {
      return {
        ...state, newJob: {
          ...state.newJob, flightunits: payload.flightunits, name: payload.name, project: payload.project,
          assignedPersonnel: payload.assignedPersonnel, description: payload.description, startDate: payload.startDate, endDate: payload.endDate,
          id: payload.id, apiType: "patch", services: payload.services, manager: payload.manager || { name: "", address: "", phone: "" },
          client: payload.client || { name: "", address: "", phone: "" }, type: payload.type || {}, invoice: payload.invoice, files: payload.files
        }
      }
    },
    clearDataForNewJob: (state) => {
      return {
        ...state, newJob: {
          ...state.newJob, flightunits: [], name: "", project: "",
          assignedPersonnel: [], description: "", startDate: "", endDate: "",
          id: "", apiType: "post", files: [], services: [], users: [], missions: [], jobTypes: [], clients: [],
          total: 0,
          otherBills: "", projectName: "", orgId: "", orgName: "", orgDescription: "",
          orgManager: "", managerEmail: "", type: "", managerName: "", creationDate: "", billingAddressName: "",
          billingAddressLocation: "", billingAddressPhone: "", shippingAddressName: "", shippingAddressLocation: "", shippingAddressPhone: "",
          manager: { name: "", address: "", phone: "" }, client: { name: "", address: "", phone: "" }, invoice: []
        }
      }
    },
    updateConfirmedPilot: (state, { payload }) => {

      state.newJob.flightunits.map((element: any) => {

        if (element.drone.id === payload.droneId) {
          if (element.pilot) {
            if (element.pilot.id === payload.pilot.id) {
              element.pilot = { id: "" }
            }
            else {
              element.pilot = payload.pilot
            }
          }
          else {
            element.pilot = payload.pilot
          }
        }
      })
    },
    setFlightUnits: (state, { payload }) => {
      state.newJob.flightunits = payload
    },
    updateConfirmedMission: (state, { payload }) => {
      state.newJob.flightunits.map((element: any) => {
        if (element.drone.id === payload.droneId) {
          if (element.missions.includes(payload.mission)) {
            element.missions.filter((elem: any) => elem !== payload.mission)
          }
          else {
            element.missions.push(payload.mission)
          }
        }
      })
    },
    calculateTotal: ((state, { payload }) => {
      let total = 0
      state.newJob.services.map((element: any) => {
        total += parseInt(element.total)
      })
      state.newJob.total = total
    }),
    updateManager: ((state, { payload }) => {
      return { ...state, newJob: { ...state.newJob, manager: payload } }
    }),
    updateClient: ((state, { payload }) => {
      return { ...state, newJob: { ...state.newJob, client: payload } }
    }),
    updateType: ((state, { payload }) => {
      return { ...state, newJob: { ...state.newJob, type: payload } }
    }),
    updateUnavailableEntities: (state, { payload }) => {
      return { ...state, newJob: { ...state.newJob, unavailable: payload } }
    }
  },

  extraReducers(builder) {
    builder
      .addCase(getSingleProject.pending, (state) => {
      })
      .addCase(getSingleProject.fulfilled, (state, { payload }) => {
        return {
          ...state, newJob: {
            ...state.newJob, org: { name: payload.org.name } || "", orgName: payload.org.name,
            orgId: payload.org.id, projectName: payload.name, project: payload.id,
            orgManager: payload.manager.name, jobTypes: payload.jobTypes, missions: payload.missions, users: payload.users, clients: payload.clients,
            site: payload.site, managerEmail: payload.manager.email
          }
        }
      })
  }

});

export default droneCreation.reducer;
export const {
  updateType,
  updateAllData,
  updateMapLayers,
  updateMissionDetails,
  updateMapLayersWithIndex,
  updateCurrentPosition,
  addUser,
  updateAssignedDrone,
  updateFiles,
  addService,
  updateflightunits,
  updateJobCreationStringData,
  updateCreationDate,
  updateServiceData,
  updateStartAndEndDates,
  arrangeDataForEdit,
  clearDataForNewJob,
  updateConfirmedPilot,
  updateConfirmedMission,
  calculateTotal,
  updateDroneJob,
  updateManager,
  updateClient,
  removeFlightUnit,
  setFlightUnits,
  removeLocalInvoice,
  updateUnavailableEntities
} = droneCreation.actions;
// type
